.linked-calendars {
    .linked {
        width: 45%;
        @media only screen and (max-width: 41rem) {
            width: 100%
        }
    }
    .nextMonth {
        opacity: 0.5;
    }
}