$squareRatio: percentage(1/7);
$squareRatioHalf: percentage(1/14);
.CalendarWidget {
    width: 100%;
    max-width: 64rem;
    margin: 3.2rem auto;
    .calendar-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 4%;
        margin-right: 4%;
        margin-bottom: 3.2rem;
        .today {
            cursor: default;
            color: $color-blue;
            font-size: 3.2rem;
            -webkit-transition: all .25s ease;
            -o-transition: all .25s ease;
            transition: all .25s ease;
            &:hover {
                color: #f1f1f1;
            }
        }
        .middle {
            text-align: center;
            order: 0;
        }
        .left {
            text-align: center;
            order: -1;
        }
        .right {
            text-align: center;
            order: 1;
        }
        .month-navigation {
            width: 7rem;
            svg {
                width: 2.4rem;
                fill: $primary-color;
                -webkit-transition: all .25s ease;
                -o-transition: all .25s ease;
                transition: all .25s ease;
            }
            button {
                width: 3.2rem;
                height: 3.2rem;
                border: none;
                background: none;
                &:hover svg {
                    fill: $color-blue
                }
            }
        }
        .year-month-selectors {
            select.year-selector {
                padding-left: 0.5rem;
            }
            select {
                width: 5rem;
                outline: none;
                font-size: 1.6rem;
                background: $color-dark-grey;
                color: $primary-color;
                -webkit-appearance: none;
                padding-left: 1rem;
                margin: 0.4rem;
                padding-top: 0.2rem;
                padding-bottom: 0.2rem;
                border: none;
                border-radius: 0;
                border-bottom: 0.2rem dashed;
                -webkit-transition: all .25s ease;
                -o-transition: all .25s ease;
                transition: all .25s ease;
            }
            select:hover {
                color: $color-blue
            }
        }
    }
    .row {
        display: flex;
    }
    .day {
        cursor: default;
        width: $squareRatio;
        padding-top: $squareRatioHalf - 2;
        padding-bottom: $squareRatioHalf - 2;
        text-align: center;
        font-weight: bold;
    }
    .calendar-view {
        display: flex;
        flex-direction: column;
    }
    .week-header .day {
        padding-top: 1%;
        padding-bottom: 1%;
        margin-bottom: 2%;
    }
    .month-grid .day {
        &:hover {
            outline: dashed 0.2rem $color-blue;
            outline-offset: -0.4rem;
        }
    }
    .month-grid .day.today {
        background-color: $color-blue;
        color: $color-dark-grey;
        outline: solid 0.2rem $color-dark-grey;
        outline-offset: -0.4rem;
        &:hover {
            outline-style: dashed;
        }
    }
    .spill {
        color: darken($color: $primary-color, $amount: 33);
        font-weight: normal;
    }
}