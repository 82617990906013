.ts-clock {
  & .toolbox {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    & p {
      width: 16rem;
      align-self: center;
      display: flex;
      justify-content: space-between;
    }
    & .options {
      width: 26rem;
      align-self: center;
      display: flex;
      justify-content: space-between;
      & select.snap-selector {
        margin-left: 0.5rem;
      }
    }

    & .btn {
      background: none;
      border: none;
      color: $color-blue;
      font-weight: bold;
      font-size: 1.5rem;
    }
  }
  & .widgets .GDCalendar {
    margin: 1rem auto;
  }
}
