.GDClock {
  background-color: #ffffff;
  &_Header {
    background-color: $gd-clock-header;
    height: 8rem;
    display: flex;
    justify-content: center;
    font-size: 5rem;
    color: $gd-clock-primary;
  }

  &_Hours-btn,
  &_Minutes-btn {
    color: $gd-clock-primary;
    opacity: 0.75;
    border: none;
    background: none;

    width: 8rem;
    font-size: 5rem;
  }

  &_Hours-btn.selected,
  &_Minutes-btn.selected {
    opacity: 1;
  }

  &_Hours_amPm {
    color: $gd-clock-primary;
    width: 4rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &_Hours_am-btn,
  &_Hours_pm-btn {
    color: $gd-clock-primary;
    opacity: 0.75;
    border: none;
    background: none;
    font-size: 2rem;
    flex-grow: 1;
  }
  &_Hours_am-btn.selected,
  &_Hours_pm-btn.selected {
    opacity: 1;
  }

  &_View {
    padding: 2rem;
  }
}
